import _process from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var module = {
  exports: exports
};
var process = _process;

/*
 * [js-sha1]{@link https://github.com/emn178/js-sha1}
 *
 * @version 0.6.0
 * @author Chen, Yi-Cyuan [emn178@gmail.com]
 * @copyright Chen, Yi-Cyuan 2014-2017
 * @license MIT
 */

/*jslint bitwise: true */
(function () {
  'use strict';

  var root = typeof window === "object" ? window : {};
  var NODE_JS = !root.JS_SHA1_NO_NODE_JS && typeof process === "object" && process.versions && process.versions.node;

  if (NODE_JS) {
    root = _global;
  }

  var COMMON_JS = !root.JS_SHA1_NO_COMMON_JS && true && module.exports;
  var AMD = false;
  var HEX_CHARS = "0123456789abcdef".split("");
  var EXTRA = [-2147483648, 8388608, 32768, 128];
  var SHIFT = [24, 16, 8, 0];
  var OUTPUT_TYPES = ["hex", "array", "digest", "arrayBuffer"];
  var blocks = [];

  var createOutputMethod = function (outputType) {
    return function (message) {
      return new Sha1(true).update(message)[outputType]();
    };
  };

  var createMethod = function () {
    var method = createOutputMethod("hex");

    if (NODE_JS) {
      method = nodeWrap(method);
    }

    method.create = function () {
      return new Sha1();
    };

    method.update = function (message) {
      return method.create().update(message);
    };

    for (var i = 0; i < OUTPUT_TYPES.length; ++i) {
      var type = OUTPUT_TYPES[i];
      method[type] = createOutputMethod(type);
    }

    return method;
  };

  var nodeWrap = function (method) {
    var crypto = eval("require('crypto')");
    var Buffer = eval("require('buffer').Buffer");

    var nodeMethod = function (message) {
      if (typeof message === "string") {
        return crypto.createHash("sha1").update(message, "utf8").digest("hex");
      } else if (message.constructor === ArrayBuffer) {
        message = new Uint8Array(message);
      } else if (message.length === undefined) {
        return method(message);
      }

      return crypto.createHash("sha1").update(new Buffer(message)).digest("hex");
    };

    return nodeMethod;
  };

  function Sha1(sharedMemory) {
    if (sharedMemory) {
      blocks[0] = blocks[16] = blocks[1] = blocks[2] = blocks[3] = blocks[4] = blocks[5] = blocks[6] = blocks[7] = blocks[8] = blocks[9] = blocks[10] = blocks[11] = blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
      (this || _global).blocks = blocks;
    } else {
      (this || _global).blocks = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    }

    (this || _global).h0 = 1732584193;
    (this || _global).h1 = 4023233417;
    (this || _global).h2 = 2562383102;
    (this || _global).h3 = 271733878;
    (this || _global).h4 = 3285377520;
    (this || _global).block = (this || _global).start = (this || _global).bytes = (this || _global).hBytes = 0;
    (this || _global).finalized = (this || _global).hashed = false;
    (this || _global).first = true;
  }

  Sha1.prototype.update = function (message) {
    if ((this || _global).finalized) {
      return;
    }

    var notString = typeof message !== "string";

    if (notString && message.constructor === root.ArrayBuffer) {
      message = new Uint8Array(message);
    }

    var code,
        index = 0,
        i,
        length = message.length || 0,
        blocks = (this || _global).blocks;

    while (index < length) {
      if ((this || _global).hashed) {
        (this || _global).hashed = false;
        blocks[0] = (this || _global).block;
        blocks[16] = blocks[1] = blocks[2] = blocks[3] = blocks[4] = blocks[5] = blocks[6] = blocks[7] = blocks[8] = blocks[9] = blocks[10] = blocks[11] = blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
      }

      if (notString) {
        for (i = (this || _global).start; index < length && i < 64; ++index) {
          blocks[i >> 2] |= message[index] << SHIFT[i++ & 3];
        }
      } else {
        for (i = (this || _global).start; index < length && i < 64; ++index) {
          code = message.charCodeAt(index);

          if (code < 128) {
            blocks[i >> 2] |= code << SHIFT[i++ & 3];
          } else if (code < 2048) {
            blocks[i >> 2] |= (192 | code >> 6) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (128 | code & 63) << SHIFT[i++ & 3];
          } else if (code < 55296 || code >= 57344) {
            blocks[i >> 2] |= (224 | code >> 12) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (128 | code >> 6 & 63) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (128 | code & 63) << SHIFT[i++ & 3];
          } else {
            code = 65536 + ((code & 1023) << 10 | message.charCodeAt(++index) & 1023);
            blocks[i >> 2] |= (240 | code >> 18) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (128 | code >> 12 & 63) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (128 | code >> 6 & 63) << SHIFT[i++ & 3];
            blocks[i >> 2] |= (128 | code & 63) << SHIFT[i++ & 3];
          }
        }
      }

      (this || _global).lastByteIndex = i;
      (this || _global).bytes += i - (this || _global).start;

      if (i >= 64) {
        (this || _global).block = blocks[16];
        (this || _global).start = i - 64;
        this.hash();
        (this || _global).hashed = true;
      } else {
        (this || _global).start = i;
      }
    }

    if ((this || _global).bytes > 4294967295) {
      (this || _global).hBytes += (this || _global).bytes / 4294967296 << 0;
      (this || _global).bytes = (this || _global).bytes % 4294967296;
    }

    return this || _global;
  };

  Sha1.prototype.finalize = function () {
    if ((this || _global).finalized) {
      return;
    }

    (this || _global).finalized = true;
    var blocks = (this || _global).blocks,
        i = (this || _global).lastByteIndex;
    blocks[16] = (this || _global).block;
    blocks[i >> 2] |= EXTRA[i & 3];
    (this || _global).block = blocks[16];

    if (i >= 56) {
      if (!(this || _global).hashed) {
        this.hash();
      }

      blocks[0] = (this || _global).block;
      blocks[16] = blocks[1] = blocks[2] = blocks[3] = blocks[4] = blocks[5] = blocks[6] = blocks[7] = blocks[8] = blocks[9] = blocks[10] = blocks[11] = blocks[12] = blocks[13] = blocks[14] = blocks[15] = 0;
    }

    blocks[14] = (this || _global).hBytes << 3 | (this || _global).bytes >>> 29;
    blocks[15] = (this || _global).bytes << 3;
    this.hash();
  };

  Sha1.prototype.hash = function () {
    var a = (this || _global).h0,
        b = (this || _global).h1,
        c = (this || _global).h2,
        d = (this || _global).h3,
        e = (this || _global).h4;
    var f,
        j,
        t,
        blocks = (this || _global).blocks;

    for (j = 16; j < 80; ++j) {
      t = blocks[j - 3] ^ blocks[j - 8] ^ blocks[j - 14] ^ blocks[j - 16];
      blocks[j] = t << 1 | t >>> 31;
    }

    for (j = 0; j < 20; j += 5) {
      f = b & c | ~b & d;
      t = a << 5 | a >>> 27;
      e = t + f + e + 1518500249 + blocks[j] << 0;
      b = b << 30 | b >>> 2;
      f = a & b | ~a & c;
      t = e << 5 | e >>> 27;
      d = t + f + d + 1518500249 + blocks[j + 1] << 0;
      a = a << 30 | a >>> 2;
      f = e & a | ~e & b;
      t = d << 5 | d >>> 27;
      c = t + f + c + 1518500249 + blocks[j + 2] << 0;
      e = e << 30 | e >>> 2;
      f = d & e | ~d & a;
      t = c << 5 | c >>> 27;
      b = t + f + b + 1518500249 + blocks[j + 3] << 0;
      d = d << 30 | d >>> 2;
      f = c & d | ~c & e;
      t = b << 5 | b >>> 27;
      a = t + f + a + 1518500249 + blocks[j + 4] << 0;
      c = c << 30 | c >>> 2;
    }

    for (; j < 40; j += 5) {
      f = b ^ c ^ d;
      t = a << 5 | a >>> 27;
      e = t + f + e + 1859775393 + blocks[j] << 0;
      b = b << 30 | b >>> 2;
      f = a ^ b ^ c;
      t = e << 5 | e >>> 27;
      d = t + f + d + 1859775393 + blocks[j + 1] << 0;
      a = a << 30 | a >>> 2;
      f = e ^ a ^ b;
      t = d << 5 | d >>> 27;
      c = t + f + c + 1859775393 + blocks[j + 2] << 0;
      e = e << 30 | e >>> 2;
      f = d ^ e ^ a;
      t = c << 5 | c >>> 27;
      b = t + f + b + 1859775393 + blocks[j + 3] << 0;
      d = d << 30 | d >>> 2;
      f = c ^ d ^ e;
      t = b << 5 | b >>> 27;
      a = t + f + a + 1859775393 + blocks[j + 4] << 0;
      c = c << 30 | c >>> 2;
    }

    for (; j < 60; j += 5) {
      f = b & c | b & d | c & d;
      t = a << 5 | a >>> 27;
      e = t + f + e - 1894007588 + blocks[j] << 0;
      b = b << 30 | b >>> 2;
      f = a & b | a & c | b & c;
      t = e << 5 | e >>> 27;
      d = t + f + d - 1894007588 + blocks[j + 1] << 0;
      a = a << 30 | a >>> 2;
      f = e & a | e & b | a & b;
      t = d << 5 | d >>> 27;
      c = t + f + c - 1894007588 + blocks[j + 2] << 0;
      e = e << 30 | e >>> 2;
      f = d & e | d & a | e & a;
      t = c << 5 | c >>> 27;
      b = t + f + b - 1894007588 + blocks[j + 3] << 0;
      d = d << 30 | d >>> 2;
      f = c & d | c & e | d & e;
      t = b << 5 | b >>> 27;
      a = t + f + a - 1894007588 + blocks[j + 4] << 0;
      c = c << 30 | c >>> 2;
    }

    for (; j < 80; j += 5) {
      f = b ^ c ^ d;
      t = a << 5 | a >>> 27;
      e = t + f + e - 899497514 + blocks[j] << 0;
      b = b << 30 | b >>> 2;
      f = a ^ b ^ c;
      t = e << 5 | e >>> 27;
      d = t + f + d - 899497514 + blocks[j + 1] << 0;
      a = a << 30 | a >>> 2;
      f = e ^ a ^ b;
      t = d << 5 | d >>> 27;
      c = t + f + c - 899497514 + blocks[j + 2] << 0;
      e = e << 30 | e >>> 2;
      f = d ^ e ^ a;
      t = c << 5 | c >>> 27;
      b = t + f + b - 899497514 + blocks[j + 3] << 0;
      d = d << 30 | d >>> 2;
      f = c ^ d ^ e;
      t = b << 5 | b >>> 27;
      a = t + f + a - 899497514 + blocks[j + 4] << 0;
      c = c << 30 | c >>> 2;
    }

    (this || _global).h0 = (this || _global).h0 + a << 0;
    (this || _global).h1 = (this || _global).h1 + b << 0;
    (this || _global).h2 = (this || _global).h2 + c << 0;
    (this || _global).h3 = (this || _global).h3 + d << 0;
    (this || _global).h4 = (this || _global).h4 + e << 0;
  };

  Sha1.prototype.hex = function () {
    this.finalize();
    var h0 = (this || _global).h0,
        h1 = (this || _global).h1,
        h2 = (this || _global).h2,
        h3 = (this || _global).h3,
        h4 = (this || _global).h4;
    return HEX_CHARS[h0 >> 28 & 15] + HEX_CHARS[h0 >> 24 & 15] + HEX_CHARS[h0 >> 20 & 15] + HEX_CHARS[h0 >> 16 & 15] + HEX_CHARS[h0 >> 12 & 15] + HEX_CHARS[h0 >> 8 & 15] + HEX_CHARS[h0 >> 4 & 15] + HEX_CHARS[h0 & 15] + HEX_CHARS[h1 >> 28 & 15] + HEX_CHARS[h1 >> 24 & 15] + HEX_CHARS[h1 >> 20 & 15] + HEX_CHARS[h1 >> 16 & 15] + HEX_CHARS[h1 >> 12 & 15] + HEX_CHARS[h1 >> 8 & 15] + HEX_CHARS[h1 >> 4 & 15] + HEX_CHARS[h1 & 15] + HEX_CHARS[h2 >> 28 & 15] + HEX_CHARS[h2 >> 24 & 15] + HEX_CHARS[h2 >> 20 & 15] + HEX_CHARS[h2 >> 16 & 15] + HEX_CHARS[h2 >> 12 & 15] + HEX_CHARS[h2 >> 8 & 15] + HEX_CHARS[h2 >> 4 & 15] + HEX_CHARS[h2 & 15] + HEX_CHARS[h3 >> 28 & 15] + HEX_CHARS[h3 >> 24 & 15] + HEX_CHARS[h3 >> 20 & 15] + HEX_CHARS[h3 >> 16 & 15] + HEX_CHARS[h3 >> 12 & 15] + HEX_CHARS[h3 >> 8 & 15] + HEX_CHARS[h3 >> 4 & 15] + HEX_CHARS[h3 & 15] + HEX_CHARS[h4 >> 28 & 15] + HEX_CHARS[h4 >> 24 & 15] + HEX_CHARS[h4 >> 20 & 15] + HEX_CHARS[h4 >> 16 & 15] + HEX_CHARS[h4 >> 12 & 15] + HEX_CHARS[h4 >> 8 & 15] + HEX_CHARS[h4 >> 4 & 15] + HEX_CHARS[h4 & 15];
  };

  Sha1.prototype.toString = Sha1.prototype.hex;

  Sha1.prototype.digest = function () {
    this.finalize();
    var h0 = (this || _global).h0,
        h1 = (this || _global).h1,
        h2 = (this || _global).h2,
        h3 = (this || _global).h3,
        h4 = (this || _global).h4;
    return [h0 >> 24 & 255, h0 >> 16 & 255, h0 >> 8 & 255, h0 & 255, h1 >> 24 & 255, h1 >> 16 & 255, h1 >> 8 & 255, h1 & 255, h2 >> 24 & 255, h2 >> 16 & 255, h2 >> 8 & 255, h2 & 255, h3 >> 24 & 255, h3 >> 16 & 255, h3 >> 8 & 255, h3 & 255, h4 >> 24 & 255, h4 >> 16 & 255, h4 >> 8 & 255, h4 & 255];
  };

  Sha1.prototype.array = Sha1.prototype.digest;

  Sha1.prototype.arrayBuffer = function () {
    this.finalize();
    var buffer = new ArrayBuffer(20);
    var dataView = new DataView(buffer);
    dataView.setUint32(0, (this || _global).h0);
    dataView.setUint32(4, (this || _global).h1);
    dataView.setUint32(8, (this || _global).h2);
    dataView.setUint32(12, (this || _global).h3);
    dataView.setUint32(16, (this || _global).h4);
    return buffer;
  };

  var exports = createMethod();

  if (COMMON_JS) {
    module.exports = exports;
  } else {
    root.sha1 = exports;

    if (AMD) {
      undefined(function () {
        return exports;
      });
    }
  }
})();

export default module.exports;